<template>
  <div id="rptlist">
    <mt-header fixed :title="'('+getTitle(querytype)+')'+'报告列表'">
<!--      <mt-button slot="left" style=" width: 90px"-->
<!--                 @click="back()"      icon="back">返回</mt-button>-->
    </mt-header>

<div class="content">
  <mt-cell v-for="( item,index ) in  datalist"  :label="item.JiandangRQ"
 is-link
           v-on:click.native="rptclick(item)"
           :title="'('+ getTitle(querytype)+')'+'查体报告单'"   :key="index">

    <img slot="icon" src="@/assets/tabbar/consulting_selected.png" width="24" height="24"> <span>报告查看</span>
  </mt-cell>
</div>


  </div>

</template>
<script>
import {Indicator, Toast} from 'mint-ui';
// import axios from "axios";
// import axios from "axios";

import DialogUtil from "@/common/DialogUtil";

  export default {
  name: "rptlist",
  data() {
    return {
      querytype:'',
      pathinfo:'',
      form: {
        Xingming: '张秀芝',
        Shenfenzheng: '370306195909133528',
        Leixing: ''
      },
      datalist:[],
    };
  },
  created(){
    this.querytype=this.$route.query.type
    this.datalist=JSON.parse( this.$route.query.data);
   // Toast(JSON.stringify(  this.datalist))
  },
  methods: {
    getTitle(stype) {
      let sRet = ''
      if (stype == 1) {
        sRet = "两癌筛查";
      } else if (stype == 2) {
        sRet = "孕优";
      } else if (stype == 3) {
        sRet = "婚检";
      } else if (stype == 4) {
        sRet = "产检";
      }
      return sRet;
    },
    rptclick(item){
      let that = this;
      Indicator.open({text:'加载...',spinnerType:'fading-circle'})
      let hisurl='/YYT_Report_DataSend'
      this.$axios.post(hisurl,item).then((res) => {
        let code=res.data.code;
        that.pathinfo=res.data.pathinfo;
        let filename=res.data.filename;

        Indicator.close()
         //Toast(that.pathinfo);
        if (code==='10001')
           this.$router.push({ path:'/hospital/report/rptviewer', query:{url:that.pathinfo
             ,type:that.querytype,filename:filename,rn:DialogUtil.randomn(8)}});
         else {
           Toast('YYT_Report_DataSend查询失败('+JSON.stringify( item)+'),错误信息:'+res.data.msg||res.data.info);
         }
      }).catch(err => {
        Indicator.close()
        Toast(JSON.stringify( err));
      }).finally(() => {
        Indicator.close()
      })
    },
    back() {
      this.$router.go(-1);
    },
  }
};
</script>
<style scoped>
.content {
margin-top: 50px;
}
.mint-header {
  font-size: 18px;
  background-color: #eb89a9;
}
</style>